import { Component, Inject, OnInit, Injectable, AfterViewInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import * as $ from 'jquery';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
  @Injectable({
    providedIn: 'root',
  })
/** contact component*/
export class ContactComponent implements OnInit, AfterViewInit {
  message: string;
  public contact: Contact = { id: 0, firstName: '', lastName: '', email: '', phone: '', company: '' };
  public HttpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
  };


  constructor(private http: HttpClient,
              @Inject('BASE_URL') private baseUrl: string ) { }

  ngOnInit(): void {

    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      $("footer")
      .css("position", "absolute")
      .css("bottom", 0)
      .css("width", "100%");
    }
  }

  ngAfterViewInit(): void {
    $("#submitButton").mouseover(x => {
      $("#submitButton").css("color", "black");
    });

    $("#submitButton").mouseleave(x => {
      $("#submitButton").css("color", "white");
    });
  }

  submit() {
    this.contact.phone = this.contact.phone.replace(/\D/g, '');
    console.log(JSON.stringify(this.contact));
    this.http.post<Contact>(this.baseUrl + 'api/Contact/AddContact', JSON.stringify(this.contact), this.HttpOptions)
      .subscribe(result => { console.log("Posted" + JSON.stringify(result));
      }, error => console.error(error));

    if (this.contact.firstName !== undefined)
      this.message = "Thank you for your interest and time " + this.contact.firstName + "!";
    else {
      this.message = "Thank you for your interest and time";
    }
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}

export interface Contact {
  id?: number
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
}
