<div class="jumbotron">
  <div class="container">
    <h1 class="display-3">Welcome!</h1>
    <div class="row">
      <p class="col-8">
        Thank you for visiting my website. My name is Christian Noel Drotor and I am a graduate of North Carolina
        State University with a Bachelor's degree in Computer Science. I have a concentration in Accounting and
        enjoy exploring all areas of academics from 3D modeling to programming and configuring microcontrollers with
        peripheral devices. I have awesome parents, a loving sister (<a href="https://www.linkedin.com/in/helen-drotor/" target="_blank">Helen</a>), and best bud brother-in-law
        (<a href="https://www.linkedin.com/in/liam-anderson" target="_blank">Liam</a>).
      </p>
      <img src="assets/img/Noel.jfif" class="ml-2 mb-2 rounded" />
    </div>
    <a class="btn btn-danger btn-lg" role="button" [routerLink]='["/experience"]' id="moreButton">More Info »</a>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-md-4">
      <h2>Purpose</h2>
      <p>
        The purpose of this website is compromised of 3 different thoughts:
        </p>
        <ol>
          <li>
            Give an employeer/recruiter an idea of who I am as a person
          </li>
          <li>
            Show some of my programming skills by implementing and deploying the website
          </li>
          <li>
            Provide a quick and easy reference to access information that is relevant for the job process.
          </li>
        </ol>

      <p>
        I hope by navigating this site that you get a better understanding of my background and who I am. If you
        feel that I may be a great match for your company or you would like to contact me then you can:
        </p>

        <ul>
          <li>
            Fill out the suggested information on the contact page. (All fields are optional)
          </li>
          <li>
            Contact me at <a href="tel:9194556365">(919) 455-6365</a> or
            <a href="mailto:ndrotor@gmail.com">ndrotor@gmail.com</a>
          </li>
        </ul>
    </div>
    <div class="col-md-4">
      <h2>Why?</h2>
      <p>
        One of the questions I get asked often is why did I choose computer science as my major and why I want to be
        a developer as a profession. My answer begins back to when I was a child. If you never seen the knack, it is a funny
        and true clip of engineers.
      </p>
      <iframe class="w-100" src="https://www.youtube.com/embed/g8vHhgh6oM0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <p>
        As a kid I remember being curious of understanding how complex devices worked. However, I did not realize that
        I wanted to program until I was in high school. Over one summer my sister, <a href="https://www.linkedin.com/in/helen-drotor-000aa1158/" target="_blank">Helen Drotor</a>, and I went to a
        Computer Science camp at North Carolina State University. There within a couple of weeks we made a app of a game.
        I remember showing off the app demo to friends and family. I felt like I acomplished something when it was working.
        The next semester in high school I took a Visual Basic and SAS class. I still had the same feeling and
        enjoyed it so I made it my major. While in college my eyes were opened to all the different areas of programming such as game development, mobile,
        web, etc. I signed up for anything that peeked my interest. I feel like I'm the type of person that likes to keep
        learning and growing. With technology there is no lack of that.
      </p>
    </div>
    <div class="col-md-4">
      <h2>Expectations</h2>
      <p>
        My expectations in general are actually really simple. If we take care of one another then no one will ever want.
        What I look for in a company or an individual is someone that is truthful and understanding. If I fit
        in a niche and I am treated well, then you have my dedication and loyality to your cause. I also like to have
        a personal life, which takes time, so I don't want to have to make work my life. However, most the time I like to continue
        thinking, planning and coding on free time like right now.
      </p>
      <br />
      <p>
        In the perspective of a professional developer, I am looking to join a community that is both independent and helpful.
        As a student I was taught to answer my own questions. However, I realized sometimes it was
        advantageous to be advised on what to research as some solutions were more efficient than others. I would like to be
        mentored but not have the feeling like I am a burden. If anyone has any suggestions other than a company such
        as a group or organization through an open source project then please let me know through an email. I'm currently
        looking to add experience there too. My goal is to be the next generation of a company or expert to help other
        programmers in the community.
      </p>
    </div>
  </div>
</div> <!-- /container -->
