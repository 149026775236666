import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { StlModelViewerModule, StlModelViewerComponent } from 'angular-stl-model-viewer';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { ExperienceComponent } from './experience/experience.component';
import { ContactComponent } from './contact/contact.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { AboutWebsiteComponent } from './about-website/about-website.component';
import { AboutWebsiteModule } from './about-website/about-website.module';
import { CoolComponent } from './cool/cool.component';
import { LiamSectionComponent, ModelDirective } from './liam-section/liam-section.component';
import { WebGlFinalProjectComponent } from './web-gl-final-project/web-gl-final-project.component';

@NgModule({
  entryComponents: [StlModelViewerComponent],
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    CoolComponent,
    ExperienceComponent,
    ContactComponent,
    LiamSectionComponent,
    WebGlFinalProjectComponent,
    ModelDirective
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    AboutWebsiteModule,
    StlModelViewerModule,
    RouterModule.forRoot([
      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'cool', component: CoolComponent },
      { path: 'experience', component: ExperienceComponent },
      { path: 'contact', component: ContactComponent, canDeactivate: [CanDeactivateGuard] },
      { path: 'about-website', component: AboutWebsiteComponent },
      { path: 'liam', component: LiamSectionComponent },
      { path: 'game', component: WebGlFinalProjectComponent }
    ])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
