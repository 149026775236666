import { Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef, TemplateRef } from '@angular/core';
import * as THREE from 'three';
import { StlModelViewerComponent } from 'angular-stl-model-viewer';
import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[model]',
})
export class ModelDirective {
  constructor(public viewContainerRef: ViewContainerRef) { }
}

@Component({
  selector: 'app-liam-section',
  templateUrl: './liam-section.component.html',
  styleUrls: ['./liam-section.component.css'],
})
export class LiamSectionComponent implements OnInit {

  showModel: boolean[] = [];
  assetPath: string = "../../assets/parts/";
  stlModelNames: string[] = ["Anycubic_Air_Deflector", "Drotor_Door_Ramp", "Handheld", "Knife_Block_Hooks", "Stove_Handle_Caps", "Subaru_Phone_Holder"];
  current: number = 0;
  models: Map<string, ModelProperty> = new Map();
  @ViewChild(ModelDirective, { static: true }) model: ModelDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {
    // set up variables
    for (var model in this.stlModelNames) {
      this.models.set(this.stlModelNames[model], new ModelProperty());
    }
    this.setModelProperties();
  }

  ngOnInit() {
    for (var model in this.stlModelNames) {
      this.models.get(this.stlModelNames[model]).stlModelPath = this.assetPath + this.stlModelNames[model] + ".stl";
    }
    this.loadComponent();
    //$('#model_css').css('display', 'block')
    //  .css('height', $('#model_css').width());
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(StlModelViewerComponent);

    const viewContainerRef = this.model.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    let element: HTMLElement = <HTMLElement>componentRef.location.nativeElement;
    element.style.display = "block";
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      element.style.width = "500px";
      element.style.height = "500px";
    } else {
      element.style.height = $("#stlDiv").width() + "px";
      element.style.width = $("#stlDiv").width() + "px";
    }

    (<StlModelViewerComponent>componentRef.instance).stlModel = this.models.get(this.stlModelNames[this.current]).stlModelPath;
    (<StlModelViewerComponent>componentRef.instance).camera = this.models.get(this.stlModelNames[this.current]).camera;
    (<StlModelViewerComponent>componentRef.instance).light = this.models.get(this.stlModelNames[this.current]).light;
    (<StlModelViewerComponent>componentRef.instance).material = this.models.get(this.stlModelNames[this.current]).material;
    (<StlModelViewerComponent>componentRef.instance).meshOptions = this.models.get(this.stlModelNames[this.current]).meshOptions;
    (<StlModelViewerComponent>componentRef.instance).scene = this.models.get(this.stlModelNames[this.current]).scene;
  }

  public next() {
    this.current = (this.current + 1) % this.models.size;
    this.loadComponent();
  }

  public previous() {
    if (this.current === 0) {
      this.current = this.models.size - 1;
    } else {
      this.current--;
    }
    this.loadComponent();
  }

  private setModelProperties() {
    const modelColor = new THREE.Color(0x444444);
    // model #1
    this.models.get(this.stlModelNames[0]).camera.position.set(0, -5, 0);  //rotate, translate, angle
    this.models.get(this.stlModelNames[0]).material.color = modelColor;
    //this.material.wireframe = true;
    this.models.get(this.stlModelNames[0]).light.position.set(0, 0, 0);
    this.models.get(this.stlModelNames[0]).light.color.set(new THREE.Color(0xe9e0c9))
    this.models.get(this.stlModelNames[0]).scene.add(this.models.get(this.stlModelNames[0]).light);
    //let texture = new THREE.TextureLoader().load("../../assets/img/angular.png");
    this.models.get(this.stlModelNames[0]).scene.background = new THREE.Color(0x000000);

    //model #2
    this.models.get(this.stlModelNames[1]).camera.position.set(-4, 0, 0);
    this.models.get(this.stlModelNames[1]).material.color = modelColor;
    this.models.get(this.stlModelNames[1]).light.position.set(0, 0, 0);
    this.models.get(this.stlModelNames[1]).light.color.set(new THREE.Color(0xe9e0c9))
    this.models.get(this.stlModelNames[1]).scene.add(this.models.get(this.stlModelNames[0]).light);
    this.models.get(this.stlModelNames[1]).scene.background = new THREE.Color(0x000000);
    this.models.get(this.stlModelNames[1]).meshOptions.position = new THREE.Vector3(0, -1, 0);
    this.models.get(this.stlModelNames[1]).meshOptions.scale = new THREE.Vector3(.015, .015, .015);

    //model #3
    this.models.get(this.stlModelNames[2]).camera.position.set(0, -5, 0);  //rotate, translate, angle
    this.models.get(this.stlModelNames[2]).material.color = modelColor;
    this.models.get(this.stlModelNames[2]).light.position.set(0, 0, 0);
    this.models.get(this.stlModelNames[2]).light.color.set(new THREE.Color(0xe9e0c9))
    this.models.get(this.stlModelNames[2]).scene.add(this.models.get(this.stlModelNames[0]).light);
    this.models.get(this.stlModelNames[2]).meshOptions.position = new THREE.Vector3(1, 0, 0);
    this.models.get(this.stlModelNames[2]).meshOptions.scale = new THREE.Vector3(.015, .015, .015);
    this.models.get(this.stlModelNames[2]).scene.background = new THREE.Color(0x000000);

    //model #4
    this.models.get(this.stlModelNames[3]).camera.position.set(-5, 0, 0);  //rotate, translate, angle
    this.models.get(this.stlModelNames[3]).material.color = modelColor;
    this.models.get(this.stlModelNames[3]).light.position.set(0, 0, 0);
    this.models.get(this.stlModelNames[3]).light.color.set(new THREE.Color(0xe9e0c9))
    this.models.get(this.stlModelNames[3]).scene.add(this.models.get(this.stlModelNames[0]).light);
    this.models.get(this.stlModelNames[3]).meshOptions.scale = new THREE.Vector3(.03, .03, .03);
    this.models.get(this.stlModelNames[3]).scene.background = new THREE.Color(0x000000);

    //model #5
    this.models.get(this.stlModelNames[4]).camera.position.set(0, -5, 0);  //rotate, translate, angle
    this.models.get(this.stlModelNames[4]).material.color = modelColor;
    this.models.get(this.stlModelNames[4]).light.position.set(0, 0, 0);
    this.models.get(this.stlModelNames[4]).light.color.set(new THREE.Color(0xe9e0c9))
    this.models.get(this.stlModelNames[4]).scene.add(this.models.get(this.stlModelNames[0]).light);
    this.models.get(this.stlModelNames[4]).meshOptions.scale = new THREE.Vector3(.1, .1, .1);
    this.models.get(this.stlModelNames[4]).scene.background = new THREE.Color(0x000000);

    //model #6
    this.models.get(this.stlModelNames[5]).camera.position.set(0, -10, 0);  //rotate, translate, angle
    this.models.get(this.stlModelNames[5]).material.color = modelColor;
    this.models.get(this.stlModelNames[5]).light.position.set(0, 0, 0);
    this.models.get(this.stlModelNames[5]).light.color.set(new THREE.Color(0xe9e0c9))
    this.models.get(this.stlModelNames[5]).meshOptions.position = new THREE.Vector3(0, -1, 0);
    //this.models.get(this.stlModelNames[2]).meshOptions.scale = new THREE.Vector3(.015, .015, .015);
    this.models.get(this.stlModelNames[5]).scene.add(this.models.get(this.stlModelNames[0]).light);
    this.models.get(this.stlModelNames[5]).scene.background = new THREE.Color(0x000000);
  }
}

class ModelProperty extends Map{
  stlModelPath: string;
  camera: THREE.PerspectiveCamera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 15);
  material: THREE.MeshPhongMaterial = new THREE.MeshPhongMaterial();
  light: THREE.Light = new THREE.PointLight(0xffffff);
  meshOptions: any = { position: new THREE.Vector3(0, 0, 0), scale: new THREE.Vector3(.02, .02, .02) }; //translate, fix, idk
  scene: THREE.Scene = new THREE.Scene();
}
