/* GLOBAL CONSTANTS AND VARIABLES */

var quit = false;

/* assignment specific globals */
var defaultEye = vec3.fromValues(.5,  - .2,  - .5); // default eye position in world space
var defaultCenter = vec3.fromValues(0.5, 0.5, 0.5); // default view direction in world space
var defaultUp = vec3.fromValues(0, 1, 0); // default view up vector
/* webgl and geometry data */
var gl = null; // the all powerful gl object. It's all here folks!
var vertexBuffers = []; // this contains vertex coordinate lists by set, in triples
var snakeOpBuffers = [];
var colorBuffer;
var foodBuffer;
var boardBuffer;
var triangleBuffer; // lists of indices into vertexBuffers by set, in triples
var boardBufferIndex;

/* shader parameter locations */
var vPosAttribLoc; // where to put position for vertex shader
var vColorAttribLoc;
var pvmMatrixULoc; // where to put project model view matrix for vertex shader
/* interaction variables */
var Eye = vec3.clone(defaultEye); // eye position in world space
var Center = vec3.clone(defaultCenter); // view direction in world space
var Up = vec3.clone(defaultUp); // view up vector in world space

/* game */
var snake = [];
var snakeOp = [];
var food = {
	x: Math.floor(Math.random() * 25) + 1,
	y: Math.floor(Math.random() * 25) + 1
};
var direction = [];
var audio = new Audio();
var music = new Audio("../../../assets/game/bit.mp3");
var canvas;
var ctx;
var img;
var resetSnake = [false, false];
// set the amount of times the death track plays (kind of gets annoying after some time)
var songRep = 2;
var end = false;
var human = true;

// ASSIGNMENT HELPER FUNCTIONS

// does stuff when keys are pressed
function handleKeyDown(event) {
	switch (event.code) {
	case "Digit2": // toggle player
		human = !human;
		break;
	case "KeyA": // translate left
		if (direction[0] !== 'right') {
			direction[0] = 'left';
		}
		break;
	case "KeyD": // translate right, rotate right with shift
		// translateModel(vec3.scale(temp, viewRight, -viewDelta));
		if (direction[0] !== 'left') {
			direction[0] = 'right';
		}
		break;
	case "KeyS": // translate backward
		// translateModel(vec3.scale(temp, lookAt, -viewDelta));
		if (direction[0] !== 'up') {
			direction[0] = 'down';
		}
		break;
	case "KeyW": // translate forward, rotate down with shift
		// translateModel(vec3.scale(temp, lookAt, viewDelta));
		if (direction[0] !== 'down') {
			direction[0] = 'up';
		}
		break;
	case "KeyJ":
		if (direction[1] !== 'right') {
			direction[1] = 'left';
		}
		break;
	case "KeyL": // translate right, rotate right with shift
		// translateModel(vec3.scale(temp, viewRight, -viewDelta));
		if (direction[1] !== 'left') {
			direction[1] = 'right';
		}
		break;
	case "KeyK": // translate backward
		// translateModel(vec3.scale(temp, lookAt, -viewDelta));
		if (direction[1] !== 'up') {
			direction[1] = 'down';
		}
		break;
	case "KeyI": // translate forward, rotate down with shift
		// translateModel(vec3.scale(temp, lookAt, viewDelta));
		if (direction[1] !== 'down') {
			direction[1] = 'up';
		}
		break;
	} // end switch
} // end handleKeyDown

// set up the webGL environment
function setupWebGL() {

	// Set up keys
	document.onkeydown = handleKeyDown; // call this when key pressed
	// Get the image canvas, render an image in it
	canvas = document.getElementById("myImageCanvas"); // create a 2d canvas
	canvas.width = canvas.offsetWidth;
	canvas.height = canvas.offsetHeight;
	ctx = canvas.getContext("2d");

	ctx.beginPath();
	ctx.rect(0, 0, canvas.width, canvas.height);
	ctx.fillStyle = "black";
	ctx.fill();

	// create a webgl canvas and set it up
	var webGLCanvas = document.getElementById("myWebGLCanvas"); // create a webgl canvas
	webGLCanvas.width = webGLCanvas.offsetWidth;
	webGLCanvas.height = webGLCanvas.offsetHeight;
	if (webGLCanvas.height !== webGLCanvas.width) {
		var temp = webGLCanvas.width;
		webGLCanvas.width = webGLCanvas.height;
		webGLCanvas.style.width = String((webGLCanvas.width / temp) * 100) + "%";
		webGLCanvas.style.marginLeft = String((temp - webGLCanvas.width) / 2) + "px";
	}
	gl = webGLCanvas.getContext("webgl"); // get a webgl object from it
	try {
		if (gl == null) {
			throw "unable to create gl context -- is your browser gl ready?";
		} else {
			gl.clearDepth(1.0); // use max when we clear the depth buffer
			gl.enable(gl.DEPTH_TEST); // use hidden surface removal (with zbuffering)
		}
	} // end try
	catch (e) {
		console.log(e);
	} // end catch

	snake[0] = {
		x: 13,
		y: 13
	};

	snakeOp[0] = {
		x: 13,
		y: 12
	}

	music.play();

} // end setupWebGL

// read models in, load them into webgl buffers
function loadModels() {
	if (!audio.paused && img !== undefined) {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = "black";
		ctx.fill();
		ctx.drawImage(img, canvas.width * (.3), 400, 500, 300);
		ctx.font = "30px Comic Sans MS";
		ctx.fillStyle = 'red';
		ctx.fillText('RED/COMP', canvas.width / 10, canvas.height / 10);
		ctx.fillText('Score: ' + String(snakeOp.length), canvas.width / 10, canvas.height / 5);
		ctx.fillStyle = 'yellow';
		ctx.fillText('V.S.', canvas.width / 2, canvas.height / 10);
		ctx.fillStyle = 'blue';
		ctx.fillText('BLUE/YOU', canvas.width * .8, canvas.height / 10);
		ctx.fillText('Score: ' + String(snake.length), canvas.width * .8, canvas.height / 5);
		music.pause();
	} else if (!audio.paused) {
		music.pause();
	} else {
		img = undefined;
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = "black";
		ctx.fill();
		ctx.font = "30px Comic Sans MS";
		ctx.fillStyle = 'red';
		ctx.fillText('RED/COMP', canvas.width / 10, canvas.height / 10);
		ctx.fillText('Score: ' + String(snakeOp.length), canvas.width / 10, canvas.height / 5);
		ctx.fillStyle = 'yellow';
		ctx.fillText('V.S.', canvas.width / 2, canvas.height / 10);
		ctx.fillStyle = 'blue';
		ctx.fillText('BLUE/YOU', canvas.width * .8, canvas.height / 10);
		ctx.fillText('Score: ' + String(snake.length), canvas.width * .8, canvas.height / 5);
		music.play();
	}

	//update the models array
	if ((snake[0].x === food.x && snake[0].y === food.y) || (snakeOp[0].x === food.x && snakeOp[0].y === food.y)) {
		//operate function on snake that hit food
		if (snake[0].x === food.x && snake[0].y === food.y) {
			// record the current head
			var head = snake[0];
			// move all parts
			for (var i = snake.length; i > 0; i--) {
				snake[i] = Object.assign({}, snake[i - 1]);
			}

			//modify head to new head
			switch (direction[0]) {
			case 'left':
				head.x = head.x + 1;
				if (head.x > 25) {
					resetGame('me');
				}
				break;
			case 'right':
				head.x = head.x - 1;
				if (head.x < 1) {
					resetGame('me');
				}
				break;
			case 'down':
				head.y = head.y - 1;
				if (head.y < 1) {
					resetGame('me');
				}
				break;
			case 'up':
				head.y = head.y + 1;
				if (head.y > 25) {
					resetGame('me');
				}
				break;
			default:
			}

			//set the current head to the new head
			if (!resetSnake[0]) {
				snake[0] = Object.assign({}, head);
			} else {
				resetSnake[0] = false;
			}
		} else {
			// record the current head
			var head = snakeOp[0];
			// move all parts
			for (var i = snakeOp.length; i > 0; i--) {
				snakeOp[i] = Object.assign({}, snakeOp[i - 1]);
			}

			//modify head to new head
			switch (direction[1]) {
			case 'left':
				head.x = head.x + 1;
				if (head.x > 25)
					resetGame('op');
				break;
			case 'right':
				head.x = head.x - 1;
				if (head.x < 1) {
					resetGame('op');
				}
				break;
			case 'down':
				head.y = head.y - 1;
				if (head.y < 1)
					resetGame('op');
				break;
			case 'up':
				head.y = head.y + 1;
				if (head.y > 25)
					resetGame('op');
				break;
			default:
			}

			//set the current head to the new head
			if (!resetSnake[1]) {
				snakeOp[0] = Object.assign({}, head);
			} else {
				resetSnake[1] = false;
			}
		}
		// reset food
		food.x = Math.floor(Math.random() * 25) + 1;
		food.y = Math.floor(Math.random() * 25) + 1;
	} else {
		for (var i = snake.length - 1; i > 0; i--) {
			snake[i] = Object.assign({}, snake[i - 1]);
		}

		//modify head to new head
		switch (direction[0]) {
		case 'left':
			snake[0].x = snake[0].x + 1;
			if (snake[0].x > 25)
				resetGame('me');
			break;
		case 'right':
			snake[0].x = snake[0].x - 1;
			if (snake[0].x < 1)
				resetGame('me');
			break;
		case 'down':
			snake[0].y = snake[0].y - 1;
			if (snake[0].y < 1)
				resetGame('me');
			break;
		case 'up':
			snake[0].y = snake[0].y + 1;
			if (snake[0].y > 25)
				resetGame('me');
			break;
		default:
		}

		for (var i = 1; i < snake.length; i++) {
			if (snake[0].x === snake[i].x && snake[0].y === snake[i].y)
				resetGame('me');
		}

		// snake Opponent

		for (var i = snakeOp.length - 1; i > 0; i--) {
			snakeOp[i] = Object.assign({}, snakeOp[i - 1]);
		}

		if (!human) {
			if (snakeOp[0].x > food.x) {
				if (direction[1] !== 'left')
					direction[1] = 'right';
				else
					direction[1] = 'down';
			} else if (snakeOp[0].x < food.x) {
				if (direction[1] !== 'right')
					direction[1] = 'left';
				else
					direction[1] = 'up';
			} else if (snakeOp[0].y > food.y) {
				if (direction[1] !== 'up')
					direction[1] = 'down';
				else
					direction[1] = 'left';
			} else {
				if (direction[1] !== 'down')
					direction[1] = 'up';
				else
					direction[1] = 'right';
			}
		}

		//modify head to new head
		switch (direction[1]) {
		case 'left':
			snakeOp[0].x = snakeOp[0].x + 1;
			if (snakeOp[0].x > 25)
				resetGame('op');
			break;
		case 'right':
			snakeOp[0].x = snakeOp[0].x - 1;
			if (snakeOp[0].x < 1)
				resetGame('op');
			break;
		case 'down':
			snakeOp[0].y = snakeOp[0].y - 1;
			if (snakeOp[0].y < 1)
				resetGame('op');
			break;
		case 'up':
			snakeOp[0].y = snakeOp[0].y + 1;
			if (snakeOp[0].y > 25)
				resetGame('op');
			break;
		default:
		}

		for (var i = 1; i < snakeOp.length; i++) {
			if (snakeOp[0].x === snakeOp[i].x && snakeOp[0].y === snakeOp[i].y)
				resetGame('op');
		}

		for (var i = 0; i < snake.length; i++) {
			if (snakeOp[0].x === snake[i].x && snakeOp[0].y === snake[i].y) {
				resetGame('op');
			}
		}

		for (var i = 0; i < snakeOp.length; i++) {
			if (snake[0].x === snakeOp[i].x && snake[0].y === snakeOp[i].y) {
				resetGame('me');
			}
		}
	}
	setBuffers();
} // end load models

function setBuffers() {

	for (var part = 0; part < snake.length; part++) {
		var vertexArray = [];

		//SNAKE

		//front
		vertexArray.push((snake[part].x - 1) * .04, (snake[part].y - 1) * .04, 0);
		vertexArray.push(snake[part].x * .04, (snake[part].y - 1) * .04, 0);
		vertexArray.push(snake[part].x * .04, snake[part].y * .04, 0);
		vertexArray.push((snake[part].x - 1) * .04, snake[part].y * .04, 0);

		//back
		vertexArray.push((snake[part].x - 1) * .04, (snake[part].y - 1) * .04, .04);
		vertexArray.push((snake[part].x - 1) * .04, snake[part].y * .04, .04);
		vertexArray.push(snake[part].x * .04, snake[part].y * .04, .04);
		vertexArray.push(snake[part].x * .04, (snake[part].y - 1) * .04, .04);

		//top
		vertexArray.push((snake[part].x - 1) * .04, snake[part].y * .04, .04);
		vertexArray.push((snake[part].x - 1) * .04, snake[part].y * .04, 0);
		vertexArray.push(snake[part].x * .04, snake[part].y * .04, 0);
		vertexArray.push(snake[part].x * .04, snake[part].y * .04, .04);

		//bottom
		vertexArray.push((snake[part].x - 1) * .04, (snake[part].y - 1) * .04, .04);
		vertexArray.push(snake[part].x * .04, (snake[part].y - 1) * .04, .04);
		vertexArray.push(snake[part].x * .04, (snake[part].y - 1) * .04, 0);
		vertexArray.push((snake[part].x - 1) * .04, (snake[part].y - 1) * .04, 0);

		//right
		vertexArray.push(snake[part].x * .04, (snake[part].y - 1) * .04, .04);
		vertexArray.push(snake[part].x * .04, snake[part].y * .04, .04);
		vertexArray.push(snake[part].x * .04, snake[part].y * .04, 0);
		vertexArray.push(snake[part].x * .04, (snake[part].y - 1) * .04, 0);

		//left
		vertexArray.push((snake[part].x - 1) * .04, (snake[part].y - 1) * .04, .04);
		vertexArray.push((snake[part].x - 1) * .04, (snake[part].y - 1) * .04, 0);
		vertexArray.push((snake[part].x - 1) * .04, snake[part].y * .04, 0);
		vertexArray.push((snake[part].x - 1) * .04, snake[part].y * .04, .04);

		// send the vertex colors to webGL
		vertexBuffers[part] = gl.createBuffer(); // init empty vertex coord buffer for current set
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffers[part]); // activate that buffer
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexArray), gl.STATIC_DRAW); // coords to that buffer
	}

	for (var part = 0; part < snakeOp.length; part++) {
		// OPPONENT
		var vertexArray = [];

		//front
		vertexArray.push((snakeOp[part].x - 1) * .04, (snakeOp[part].y - 1) * .04, 0);
		vertexArray.push(snakeOp[part].x * .04, (snakeOp[part].y - 1) * .04, 0);
		vertexArray.push(snakeOp[part].x * .04, snakeOp[part].y * .04, 0);
		vertexArray.push((snakeOp[part].x - 1) * .04, snakeOp[part].y * .04, 0);

		//back
		vertexArray.push((snakeOp[part].x - 1) * .04, (snakeOp[part].y - 1) * .04, .04);
		vertexArray.push((snakeOp[part].x - 1) * .04, snakeOp[part].y * .04, .04);
		vertexArray.push(snakeOp[part].x * .04, snakeOp[part].y * .04, .04);
		vertexArray.push(snakeOp[part].x * .04, (snakeOp[part].y - 1) * .04, .04);

		//top
		vertexArray.push((snakeOp[part].x - 1) * .04, snakeOp[part].y * .04, .04);
		vertexArray.push((snakeOp[part].x - 1) * .04, snakeOp[part].y * .04, 0);
		vertexArray.push(snakeOp[part].x * .04, snakeOp[part].y * .04, 0);
		vertexArray.push(snakeOp[part].x * .04, snakeOp[part].y * .04, .04);

		//bottom
		vertexArray.push((snakeOp[part].x - 1) * .04, (snakeOp[part].y - 1) * .04, .04);
		vertexArray.push(snakeOp[part].x * .04, (snakeOp[part].y - 1) * .04, .04);
		vertexArray.push(snakeOp[part].x * .04, (snakeOp[part].y - 1) * .04, 0);
		vertexArray.push((snakeOp[part].x - 1) * .04, (snakeOp[part].y - 1) * .04, 0);

		//right
		vertexArray.push(snakeOp[part].x * .04, (snakeOp[part].y - 1) * .04, .04);
		vertexArray.push(snakeOp[part].x * .04, snakeOp[part].y * .04, .04);
		vertexArray.push(snakeOp[part].x * .04, snakeOp[part].y * .04, 0);
		vertexArray.push(snakeOp[part].x * .04, (snakeOp[part].y - 1) * .04, 0);

		//left
		vertexArray.push((snakeOp[part].x - 1) * .04, (snakeOp[part].y - 1) * .04, .04);
		vertexArray.push((snakeOp[part].x - 1) * .04, (snakeOp[part].y - 1) * .04, 0);
		vertexArray.push((snakeOp[part].x - 1) * .04, snakeOp[part].y * .04, 0);
		vertexArray.push((snakeOp[part].x - 1) * .04, snakeOp[part].y * .04, .04);

		// send the vertex colors to webGL
		snakeOpBuffers[part] = gl.createBuffer(); // init empty vertex coord buffer for current set
		gl.bindBuffer(gl.ARRAY_BUFFER, snakeOpBuffers[part]); // activate that buffer
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexArray), gl.STATIC_DRAW); // coords to that buffer
	}

	var foodArray = [];

	//front
	foodArray.push((food.x - 1) * .04, (food.y - 1) * .04, 0);
	foodArray.push(food.x * .04, (food.y - 1) * .04, 0);
	foodArray.push(food.x * .04, food.y * .04, 0);
	foodArray.push((food.x - 1) * .04, food.y * .04, 0);

	//back
	foodArray.push((food.x - 1) * .04, (food.y - 1) * .04, .04);
	foodArray.push((food.x - 1) * .04, food.y * .04, .04);
	foodArray.push(food.x * .04, food.y * .04, .04);
	foodArray.push(food.x * .04, (food.y - 1) * .04, .04);

	//top
	foodArray.push((food.x - 1) * .04, food.y * .04, .04);
	foodArray.push((food.x - 1) * .04, food.y * .04, 0);
	foodArray.push(food.x * .04, food.y * .04, 0);
	foodArray.push(food.x * .04, food.y * .04, .04);

	//bottom
	foodArray.push((food.x - 1) * .04, (food.y - 1) * .04, .04);
	foodArray.push(food.x * .04, (food.y - 1) * .04, .04);
	foodArray.push(food.x * .04, (food.y - 1) * .04, 0);
	foodArray.push((food.x - 1) * .04, (food.y - 1) * .04, 0);

	//right
	foodArray.push(food.x * .04, (food.y - 1) * .04, .04);
	foodArray.push(food.x * .04, food.y * .04, .04);
	foodArray.push(food.x * .04, food.y * .04, 0);
	foodArray.push(food.x * .04, (food.y - 1) * .04, 0);

	//left
	foodArray.push((food.x - 1) * .04, (food.y - 1) * .04, .04);
	foodArray.push((food.x - 1) * .04, (food.y - 1) * .04, 0);
	foodArray.push((food.x - 1) * .04, food.y * .04, 0);
	foodArray.push((food.x - 1) * .04, food.y * .04, .04);

	foodBuffer = gl.createBuffer();
	gl.bindBuffer(gl.ARRAY_BUFFER, foodBuffer); // activate that buffer
	gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(foodArray), gl.STATIC_DRAW); // coords to that buffer

	var boardArray = [];

	//back
	boardArray.push(0.0, 0.0, .04);
	boardArray.push(0.0, 1.0, .04);
	boardArray.push(1.0, 1.0, .04);
	boardArray.push(1.0, 0.0, .04);

	//top
	boardArray.push(0.0, 1.0, .04);
	boardArray.push(0.0, 1.0, 0);
	boardArray.push(1.0, 1.0, 0);
	boardArray.push(1.0, 1.0, .04);

	//bottom
	boardArray.push(0.0, 0.0, .04);
	boardArray.push(1.0, 0, .04);
	boardArray.push(1, 0, 0);
	boardArray.push(0, 0, 0);

	//right
	boardArray.push(1, 0, .04);
	boardArray.push(1, 1, .04);
	boardArray.push(1, 1, 0);
	boardArray.push(1, 0, 0);

	//left
	boardArray.push(0, 0, .04);
	boardArray.push(0, 0, 0);
	boardArray.push(0, 1, 0);
	boardArray.push(0, 1, .04);

	boardBuffer = gl.createBuffer();
	gl.bindBuffer(gl.ARRAY_BUFFER, boardBuffer); // activate that buffer
	gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(boardArray), gl.STATIC_DRAW); // coords to that buffer

	var boardIndiceArray = [];

	for (var i = 0; i < 20; i = i + 4) {
		boardIndiceArray.push(i + 0, i + 1, i + 2, i + 0, i + 2, i + 3);
	}

	// send the triangle indices to webGL
	boardBufferIndex = gl.createBuffer(); // init empty triangle index buffer for current tri set
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, boardBufferIndex); // activate that buffer
	gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(boardIndiceArray), gl.STATIC_DRAW); // indices to that buffer

	var indiceArray = [];

	for (var i = 0; i < 24; i = i + 4) {
		indiceArray.push(i + 0, i + 1, i + 2, i + 0, i + 2, i + 3);
	}

	// send the triangle indices to webGL
	triangleBuffer = gl.createBuffer(); // init empty triangle index buffer for current tri set
	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, triangleBuffer); // activate that buffer
	gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indiceArray), gl.STATIC_DRAW); // indices to that buffer
}

function resetGame(name) {
	console.log(snake[0].x + " " + snake[0].y);
	if (snake[0].x === 26 && snake[0].y === 1) {
		end = true;
		return;
	}
	if (name === 'me') {
		snake = [];
		snake[0] = {
			x: 13,
			y: 13
		};
		resetSnake[0] = true;
	} else {
		snakeOp = [];
		snakeOp[0] = {
			x: 13,
			y: 13
		};
		resetSnake[1] = true;
	}
	if (audio.paused && songRep !== 0) {
		audio = new Audio('../../../assets/game/snake.mp3');
		audio.play();
		setTimeout(function () {
			if (!end) {
				img = new Image();
				img.src = '../../../assets/game/snake.jpg';
				ctx.drawImage(img, canvas.width * (.3), 400, 500, 300);
			}
		}, 5000);
		songRep--;
	} else {}
}

// setup the webGL shaders
function setupShaders() {

	// define vertex shader in essl using es6 template strings
	var vShaderCode = `
        attribute vec3 aVertexPosition; // vertex position
		attribute vec3 aVertexColor;
        
        uniform mat4 upvmMatrix; // the project view model matrix
        
        varying vec3 vColor; // color

        void main(void) {  
            // vertex position
            gl_Position = upvmMatrix * vec4(aVertexPosition, 1.0);
			
			vColor = aVertexColor;
        }
    `;

	// define fragment shader in essl using es6 template strings
	var fShaderCode = `
		precision mediump float; 

        varying vec3 vColor;
        void main(void) {
			
            gl_FragColor = vec4(vColor, 1.0); 
        }
    `;

	try {
		var fShader = gl.createShader(gl.FRAGMENT_SHADER); // create frag shader
		gl.shaderSource(fShader, fShaderCode); // attach code to shader
		gl.compileShader(fShader); // compile the code for gpu execution

		var vShader = gl.createShader(gl.VERTEX_SHADER); // create vertex shader
		gl.shaderSource(vShader, vShaderCode); // attach code to shader
		gl.compileShader(vShader); // compile the code for gpu execution

		if (!gl.getShaderParameter(fShader, gl.COMPILE_STATUS)) { // bad frag shader compile
			throw "error during fragment shader compile: " + gl.getShaderInfoLog(fShader);
			gl.deleteShader(fShader);
		} else if (!gl.getShaderParameter(vShader, gl.COMPILE_STATUS)) { // bad vertex shader compile
			throw "error during vertex shader compile: " + gl.getShaderInfoLog(vShader);
			gl.deleteShader(vShader);
		} else { // no compile errors
			var shaderProgram = gl.createProgram(); // create the single shader program
			gl.attachShader(shaderProgram, fShader); // put frag shader in program
			gl.attachShader(shaderProgram, vShader); // put vertex shader in program
			gl.linkProgram(shaderProgram); // link program into gl context

			if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) { // bad program link
				throw "error during shader program linking: " + gl.getProgramInfoLog(shaderProgram);
			} else { // no shader program link errors
				gl.useProgram(shaderProgram); // activate shader program (frag and vert)

				// locate and enable vertex attributes
				vPosAttribLoc = gl.getAttribLocation(shaderProgram, "aVertexPosition"); // ptr to vertex pos attrib
				gl.enableVertexAttribArray(vPosAttribLoc); // connect attrib to array
				vColorAttribLoc = gl.getAttribLocation(shaderProgram, "aVertexColor"); // ptr to vertex pos attrib
				gl.enableVertexAttribArray(vColorAttribLoc); // connect attrib to array

				// locate vertex uniforms
				pvmMatrixULoc = gl.getUniformLocation(shaderProgram, "upvmMatrix"); // ptr to pvmmat
			} // end if no shader program link errors
		} // end if no compile errors
	} // end try
	catch (e) {
		console.log(e);
	} // end catch
} // end setup shaders

// render the loaded model
function renderModels() {
	// var hMatrix = mat4.create(); // handedness matrix
	var pMatrix = mat4.create(); // projection matrix
	var vMatrix = mat4.create(); // view matrix
	var mMatrix = mat4.create(); // model matrix
	var pvMatrix = mat4.create(); // hand * proj * view matrices
	var pvmMatrix = mat4.create(); // hand * proj * view * model matrices

	gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT); // clear frame/depth buffers

	loadModels();

	if (end) {
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		ctx.fillStyle = "black";
		ctx.fill();
		ctx.font = "30px Comic Sans MS";
		ctx.fillStyle = 'red';
		ctx.fillText('Thank You Dr. Watson & Ajinkya', canvas.width / 3, canvas.height / 2);
		music.pause();
		audio.pause();
		audio = new Audio("../../../assets/game/secret.mp3");
		audio.play();
		audio.onended = function () {
			audio = new Audio("../../../assets/game/end.mp3");
			audio.play();
		}
		return;
	}

	// set up projection and view
	// mat4.fromScaling(hMatrix,vec3.fromValues(-1,1,1)); // create handedness matrix
	mat4.perspective(pMatrix, 0.5 * Math.PI, 1, .1, 10); // create projection matrix
	mat4.lookAt(vMatrix, Eye, Center, Up); // create view matrix
	mat4.multiply(pvMatrix, pvMatrix, pMatrix); // projection
	mat4.multiply(pvMatrix, pvMatrix, vMatrix); // projection * view

	mat4.multiply(pvmMatrix, pvMatrix, mMatrix); // project * view * model
	gl.uniformMatrix4fv(pvmMatrixULoc, false, pvmMatrix); // pass in the hpvm matrix

	// SNAKE
	for (var part = 0; part < snake.length; part++) {
		gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffers[part]);
		gl.vertexAttribPointer(vPosAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

		var vertexColorArray = [];

		for (var i = 0; i < 24; i++) {
			vertexColorArray.push(0, 0.0, 1.0);
		}

		// send the vertex coords to webGL
		colorBuffer = gl.createBuffer(); // init empty vertex coord buffer for current set
		gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer); // activate that buffer
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexColorArray), gl.STATIC_DRAW); // coords to that buffer

		gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
		gl.vertexAttribPointer(vColorAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, triangleBuffer); // activate
		gl.drawElements(gl.TRIANGLES, 3 * 12, gl.UNSIGNED_SHORT, 0); // render
	}

	// OPPONENT
	for (var part = 0; part < snakeOp.length; part++) {
		gl.bindBuffer(gl.ARRAY_BUFFER, snakeOpBuffers[part]);
		gl.vertexAttribPointer(vPosAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

		var vertexColorArray = [];

		for (var i = 0; i < 24; i++) {
			vertexColorArray.push(1.0, 0.0, 0.0);
		}

		// send the vertex coords to webGL
		colorBuffer = gl.createBuffer(); // init empty vertex coord buffer for current set
		gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer); // activate that buffer
		gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexColorArray), gl.STATIC_DRAW); // coords to that buffer

		gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
		gl.vertexAttribPointer(vColorAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

		gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, triangleBuffer); // activate
		gl.drawElements(gl.TRIANGLES, 3 * 12, gl.UNSIGNED_SHORT, 0); // render
	}

	gl.bindBuffer(gl.ARRAY_BUFFER, foodBuffer);
	gl.vertexAttribPointer(vPosAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

	var vertexColorArray = [];

	for (var i = 0; i < 24; i++) {
		vertexColorArray.push((Math.floor(Math.random() * 255) + 1) / 255, (Math.floor(Math.random() * 255) + 1) / 255, (Math.floor(Math.random() * 255) + 1) / 255);
	}

	// send the vertex coords to webGL
	colorBuffer = gl.createBuffer(); // init empty vertex coord buffer for current set
	gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer); // activate that buffer
	gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexColorArray), gl.STATIC_DRAW); // coords to that buffer

	gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
	gl.vertexAttribPointer(vColorAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, triangleBuffer); // activate
	gl.drawElements(gl.TRIANGLES, 3 * 12, gl.UNSIGNED_SHORT, 0); // render

	gl.bindBuffer(gl.ARRAY_BUFFER, boardBuffer);
	gl.vertexAttribPointer(vPosAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

	vertexColorArray = [];

	for (var i = 0; i < 20; i++) {
		if (i < 4) {
			vertexColorArray.push(1.0, 1.0, 1.0);
		} else if ((i >= 4 && i < 8) || (i >= 12 && i < 20)) {
			vertexColorArray.push(1.0, 0.0, 0.0);
		} else {
			vertexColorArray.push(0.0, 0.0, 1.0);
		}
	}

	// send the vertex coords to webGL
	colorBuffer = gl.createBuffer(); // init empty vertex coord buffer for current set
	gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer); // activate that buffer
	gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(vertexColorArray), gl.STATIC_DRAW); // coords to that buffer

	gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);
	gl.vertexAttribPointer(vColorAttribLoc, 3, gl.FLOAT, false, 0, 0); // feed

	gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, boardBufferIndex); // activate
	gl.drawElements(gl.TRIANGLES, 3 * 10, gl.UNSIGNED_SHORT, 0); // render

  if (!quit) {
    setTimeout(renderModels, 100);
  } 
} // end render model


/* MAIN -- HERE is where execution begins after window load */

export function main() {
  quit = false;
  direction = [];
  music = new Audio("../../../assets/game/bit.mp3");
  human = true;
	setupWebGL(); // set up the webGL environment
	setupShaders(); // setup the webGL shaders
	renderModels(); // draw the triangles using webGL

} // end main

export function destroy() {
  quit = true;
  music.pause();
  music = new Audio();
}
