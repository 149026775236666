<div class="container">
  <h1 class="page-header mb-2">Liam's Designs</h1>
  <hr />
  <p>
    Below are a few of my brother-in-laws masterpieces. I hope you enjoy his designs as much as I do. If you
    are looking for someone who knows CAD, he has constructed models in both SolidWorks and AutoDesk. He is always
    working on a new project. He may be assistance if you are looking for someone to hire for help. There hasn't been
    anything impossible for him. He's very talented and we are lucky to have him in our lives.
  </p>
  <p>
    <em>Note to manipulate the camera on model these are the following commands:</em>
  </p>
  <ul>
    <li>LMB(left mouse button), hold and drag moves the model in retrospect to a fix point</li>
    <li>arrow key press or RMB, hold and drag moves the camera (left/right/up/down)</li>
    <li>scroll changes the camera zoom/distance to the object (backwards/forwards)</li>
  </ul>
  <p>Have fun!</p>
  <div style="text-align: -webkit-center;" id="stlDiv">
    <ng-template model></ng-template>
    <div style="margin-top: 10px"></div>
    <div container="row">
      <button (click)="previous()" class="col-4">Previous</button>
      <span class="col-4"></span>
      <button (click)="next()" class="col-4">Next</button>
    </div>
  </div>
</div>
