import { Component, OnInit, OnDestroy } from '@angular/core';
import * as App from './final/rasterize.js'
import * as $ from 'jquery';


@Component({
  selector: 'app-web-gl-final-project',
  templateUrl: './final/index.html',
  styleUrls: ['./final/rasterize.css']
})
export class WebGlFinalProjectComponent implements OnInit, OnDestroy {
  constructor() { }

  ngOnInit(): void {
    App.main();
    const width = (window.innerWidth - Number($("directions").width())) / 2;
    $("#directions").css("position", "absolute").css("z-index", 1).css("left", String(window.innerWidth / 2 - 150) + "px").css("top", "0px").css("color", "white");
  }

  ngOnDestroy(): void {
    App.destroy();
  }

}
