<body class="bg-light">
  <app-nav-menu></app-nav-menu>
  <div>
    <router-outlet></router-outlet>
  </div>
  <footer class="pl-2">
    <hr />
    <p>&copy; Noel Drotor</p>
  </footer>
</body>
