<div class="container">
  <h1 class="page-header mb-2">About My Website</h1>
  <hr />
  <h2>Hosting</h2>
  <p>
    This website is an ASP.NET MVC application served on a Kestrel web server that is used with a NGINX reverse proxy server
    that connects to the web domain www.cndrotor.com. To continuously run the website I wanted to use a machine that could be
    run continuously while taking little power. A Raspberry Pi 3 B+ fit this qualification. The web configuration explained
    follows Microsoft's recommended setup:
  </p>
  <pre><img src="../../assets/img/kestrel-to-internet.png" /></pre>
<!--  <img src="../../assets/img/kestrel-to-internet.png" />
-->  <p>
    When deciding what reverse proxy web server to use from the above choices IIS was automatically ruled out as I was running on a Linux OS, Raspbian,
    and IIS is a Microsoft-proprietary web server software.
    I did try Windows 10 IoT Core but ran into support trouble with the model of Raspberry Pi so I went with the default.
    I decided to use NGINX after looking at this <a href="http://raspberrywebserver.com/raspberrypicluster/comparing-the-performance-of-nginx-and-apache-web-servers.html"
                                                    target="_blank">website</a>.
    Based off of this and other websites I chose NGINX because:
  </p>
  <ul>
    <li>Some of the pages on my website are static which NGINX is superior</li>
    <li>Many developers said NGINX was an easy and safe configuration</li>
    <li>There are less vulnerabilities compared to Apache due to there being less modules</li>
    <li>Utilizes the CPU better and can cache to the Disk as well as RAM</li>
  </ul>
  <h2>Site</h2>
  <p>
    In a general overview the application is composed of a MVC architectual pattern where the components are:
  </p>
  <ul>
    <li>Model: A C# class object mapped to a SQLite Table</li>
    <li>View: An Angular application</li>
    <li>Controller: ASP.NET MVC controller</li>
  </ul>
  <p>
    For further information feel free to use the collapsible elements below to learn more.
  </p>
  <app-mvc></app-mvc>
  <h2 style="margin-top: 8px;">Security</h2>
  <div class="row">
    <p class="col-md-10">
      The webserver encrypts the traffic between the server and client with a X.509v3 certificate from the Let's Encrypt
      CA (Certificate Authority). The certificate contains the RSA (2048 bit) public key which encrypts the message. A signature
      from the message with a SHA-256 cyptographic hash ensures the message has not been tampered with. RSA-SHA256 encryption
      is the recommended standard.
    </p>
    <img src="../../assets/img/letsencrypt.png" class="col-md-2" />
  </div>
</div>
