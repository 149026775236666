<header>
  <nav class='navbar navbar-expand-sm navbar-toggleable-sm navbar-dark bg-dark border-bottom box-shadow mb-3'>
    <div class="row w-100">
      <a class="navbar-brand pl-2" style="color:red" [routerLink]='["/"]'>Christian Noel Drotor | Home</a>
      <button class="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target=".navbar-collapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-sm-inline-flex flex-sm-row-reverse" [ngClass]="{'show': isExpanded}">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <a class="nav-link btn-outline-danger" style="color:red" [routerLink]='["/experience"]' id="experience">Experience</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <a class="nav-link btn-outline-danger" style="color:red" [routerLink]='["/contact"]' id="contact">Contact Me</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link btn-outline-danger" style="color:red" [routerLink]='["/about-website"]' id="about">About Site</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link btn-outline-danger" style="color:red" [routerLink]='["/cool"]' id="cool">Cool Stuff</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link btn-outline-danger" style="color:red" [routerLink]='["/liam"]' id="liam">Liam's 3D Parts/Assemblies</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link btn-outline-danger" style="color:red" [routerLink]='["/game"]' id="game">Snake Break</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
