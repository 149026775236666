import { Component, AfterViewInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements AfterViewInit {
  show: boolean = false;

  ngAfterViewInit(): void {
    //$("#showDescription").on("click", event => {
    //  this.show = true;
    //});

    //$("#noShowDescription").on("click", event => {
    //  this.show = false;
    //});

    $("#moreButton").mouseover(x => {
      $("#moreButton").css("color", "black");
    });

    $("#moreButton").mouseleave(x => {
      $("#moreButton").css("color", "white");
    });
  }
}
