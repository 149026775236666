import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit{
  isExpanded: boolean;

  ngOnInit(): void {
    this.enter($("#experience"));
    this.leave($("#experience"));
    this.enter($("#contact"));
    this.leave($("#contact"));
    this.enter($("#about"));
    this.leave($("#about"));
    this.enter($("#cool"));
    this.leave($("#cool"));
    this.enter($("#liam"));
    this.leave($("#liam"));
    this.enter($("#game"));
    this.leave($("#game"));
    //$("#experience").click(() => { this.isExpanded = false; (<any>$(".navbar-collapse")).collapse('hide'); });

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      $("#game").hide();
    }
  }

  enter(element: JQuery) {
    element.mouseover(function () {
      element.css("color", "white");
    });
  }

  leave(element: JQuery) {
    element.mouseleave(function () {
      element.css("color", "red");
    });
  }
}
