import { Component } from '@angular/core';

@Component({
    selector: 'app-cool',
    templateUrl: './cool.component.html',
    styleUrls: ['./cool.component.css']
})
/** cool component*/
export class CoolComponent {
    /** cool ctor */
    constructor() {

    }
}