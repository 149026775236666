<div class="container">

  <div class="row">

    <div class="col-sm-8 blog-main">

      <div class="blog-post">
        <h1 class="blog-post-title">Experience</h1>

        <p><em>Below is my resum&eacute; along with my unofficial transcript from North Carolina State University</em></p>
        <hr>
        <h4>Resum&eacute;</h4>
        <object id="resumePdf" data="assets/pdf/christian_drotor_resume.pdf" type="application/pdf" width="100%"></object>
      <br /><hr /><br />
        <h3>Transcript</h3>
        <object id="transcriptPdf" data="assets/pdf/transcript.pdf" type="application/pdf" width="100%"></object>
      </div><!-- /.blog-post -->

    </div><!-- /.blog-main -->

    <div class="col-sm-3 offset-sm-1 blog-sidebar">
      <div class="sidebar-module sidebar-module-inset">
        <h4>Related Sites</h4>
        <p>
          Here are some external sites that contain more information
        </p>
      </div>
      <div class="sidebar-module">
        <ul>
          <li><a href="https://www.linkedin.com/in/christian-drotor-579521150/" target="_blank">LinkedIn Profile</a></li>
          <li><a href="https://stackoverflow.com/users/5030002" target="_blank">Stack Overflow Profile</a></li>
          <li><a href="https://github.com/christiandrotor" target="_blank">GitHub Projects</a></li>
        </ul>
        <h4>Resources</h4>
        <p>
          Just in case you can't view the PDFs easily
        </p>
      </div>
      <div class="sidebar-module">
        <ul>
          <li><a href="assets/pdf/christian_drotor_resume.pdf" download>Resum&eacute;</a></li>
          <li><a href="assets/pdf/transcript.pdf" download>Transcript</a></li>
        </ul>
      </div>
    </div><!-- /.blog-sidebar -->


  </div><!-- /.row -->

</div><!-- /.container -->
