<div class="container" data-spy="scroll" data-target="#doggo">
  <div class="row" style="justify-content: center">
    <div class="col-7" style="align-self: center; text-align: center;">
      <h1>Cool Stuff</h1>
      <p>
        <strong>
          Hey want to see some cool stuff? Through my journey as a software developer I have heard of helpful
          resources and want to pass the good fortune on to you for free lol!
        </strong>
      </p>
    </div>
    <div class="col-auto pl-0 pr-0" style="text-align:center;">
      <img id="doggo" src="../../assets/img/coo.gif" class="rounded" width="50%" />
    </div>
  </div>
      <br />
      <!--HumbleBundle-->
      <h4>Humble Bundle</h4>
      <p>
        "<em>
          Humble Bundle sells games, books, software, and more. Our mission is to support charity while
          providing awesome content to customers at great prices.
        </em>"
      </p> <br />
      <p>
        If you like to game, HumbleBundle is another normal video game seller. They have the newest games for
        the price equal to other competitors. They have deals and even free digital games in the store sometimes. However,
        the best part is everything goes to charity. If you buy a digital game think about getting it from
        HumbleBundle next time. <br /> <br />
        The reason why I mention HumbleBundle though is because of their bundles. Their bundles are not just
        games. I have purchased programming book bundles in a certain language for a fraction of the price. You
        have to be careful though because bundles only last a certain amount of time. Also, remember when donating
        you don't have to pay full price but the money you do donate goes to companies to pay for the content and
        charity.
      </p>
      <br />
      <a href="https://www.humblebundle.com" target="_blank">Humble Bundle</a>
      <br /> <br />

      <!--Adafruit-->
      <h4>Adafruit</h4>
      <p>
        "<em>
          Adafruit was founded in 2005 by MIT hacker & engineer, Limor "Ladyada" Fried. Her goal was to create the best
          place online for learning electronics and making the best designed products for makers of all ages and skill
          levels. Adafruit has grown to over 100+ employees in the heart of NYC with a 50,000+ sq ft. factory. Adafruit
          has expanded offerings to include tools, equipment, and electronics that Limor personally selects, tests, and
          approves before going in to the Adafruit store. Adafruit is a 100% woman owned company.
        </em>"
      </p> <br />
      <p>
        Adafruit is the best seller and teacher of electronics and coding. Every week they
        make videos of latest electronics that are presented by the founder, herself. They
        have tutorials of projects to make. My favorite personally are the <a href="https://learn.adafruit.com/firewalker-led-sneakers/overview"
                                                                              target="_blank">firewalkers</a>.
        If you begin to get good enough then you can design your own projects and appear on their Electronics Show and
        Tell! Following this company is a great way to get into programming microcontrollers, learning about IoT,
        3D printing, and forming a basic knowledge of computer/electrical engineering.
      </p>
      <br />
      <p>
        <a href="https://www.adafruit.com" target="_blank">Adafruit</a> <br />
        <a href="https://www.youtube.com/user/adafruit/featured" target="_blank">Adafruit on YouTube</a>
      </p> <br /> <br />
      <!--Hacker News-->
      <h4>Hacker News</h4>
      <p>
        This site is a collection of current news articles organized in one place that has to do with everything
        that is technological. As someone who goes to only one section of the newspaper, this is what I like to
        read instead. Special thanks to my friend, <a href="https://www.linkedin.com/in/jason-chitla-8a1a43102/" target="_blank">Jason</a>, for sharing this resource with me.
      </p>
      <br />
      <p>
        <a href="https://news.ycombinator.com/" target="_blank">Hacker News</a> <br />
      </p> <br /> <br />
      <!--Software Engineering Daily-->
      <h4>Software Engineering Daily</h4>
      <p>
        "<em>
          The scope of software engineering is growing. If you use WordPress, Ableton or PhotoShop, you are a software
          engineer. If you use Facebook, you are a software engineer–you are helping build software. On a twenty
          year time horizon, there is no “opting out” of knowing how software works. But this is OK because learning
          about software is possible (and fun) no matter your age, race, or creed.
        </em>"
      </p> <br />
      <p>
        What I like about Software Engineering is Jeff brings in employees/founders working on special projects
        that could be the future. Through the podcast they'll go in depth about the infrastructure, purpose, and
        explanations so that the listener is not lost on how everything works. Within breaks Jeff will talk about
        his endorsers who are also software companies. The amount of knowledge to learn here is huge.
      </p>
      <br />
      <p>
        <a href="https://softwareengineeringdaily.com/category/all-episodes/exclusive-content/Podcast/" target="_blank">Software Engineering Daily</a> <br />
      </p> <br /> <br />

      <!--Me-->
      <h4>Me</h4>
      <p>
        This whole website has been about all the technical details about me so here's an ice breaker.
      </p>
      <ul>
        <li>I like to play guitar</li>
        <li>My favorite and dream car is a Ford Mustang GT</li>
        <li>I try to garden whenever I can</li>
        <li>I enjoy gaming both PC and console(PS4)</li>
        <li>I mostly binge watch random YouTube(138 channels) even though I have Hulu and Spectrum</li>
        <li>I enjoy fishing, hiking, biking, and playing basketball.</li>
        <li>I'm obsessed with spicy food even though I can't handle it half the time lol</li>
        <li>I'm a shy guy who over time becomes more comfortable</li>
        <li>As you can tell from my name, I am a Christian and like to go to <a href="https://gethope.net/" target="_blank">Hope Community Church</a></li>
        <li>I enjoy sports but our conversation will probably be you telling me stats where I say, "remember that play at the end" lol</li>
        <li>I enjoy seeing current fads that are crazy (Go ahead ask me I got a few)</li>
        <li>I'm making my home into a smart home with Google Home and Hub <br />
        (Forgot to mention another cool programming site is <a href="https://ifttt.com/" target="_blank">IFTTT</a>
        for connecting apps and devices like Google Assistant)</li>
      </ul>
      <p>
        That's a bit about myself and really there is not a lot of stuff I'm not interested in.
      </p>
</div>
