import { Component } from '@angular/core';

@Component({
  selector: 'app-about-website',
  templateUrl: './about-website.component.html',
  styleUrls: ['./about-website.component.css']
})
/** about-website component*/
export class AboutWebsiteComponent {
}
