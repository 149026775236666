import { NgModule } from '@angular/core';
import { MvcComponent } from './mvc/mvc.component';
import { CommonModule } from '@angular/common';
import { AboutWebsiteComponent } from './about-website.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    AboutWebsiteComponent,
    MvcComponent,
  ],
  exports: [
    AboutWebsiteComponent,
    MvcComponent
  ],
})
export class AboutWebsiteModule {
}
