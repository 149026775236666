<div class="container">
  <h1>Contact Information</h1>
  <small id="passwordHelpBlock" class="form-text text-muted">
    All information is optional and appreciated
  </small>
  <div class="mt-4 mb-5">
    <div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputFirstName">First Name</label>
          <input [(ngModel)]="contact.firstName" [ngModelOptions]="{standalone: true}" type="text" class="form-control" id="inputFirstName" placeholder="First Name">
        </div>
        <div class="form-group col-md-6">
          <label for="inputLastName">Last Name</label>
          <input [(ngModel)]="contact.lastName" [ngModelOptions]="{standalone: true}" type="text" class="form-control" id="inputLastName" placeholder="Last Name">
        </div>
      </div>
      <div class="form-group">
        <label for="inputEmail">Email</label>
        <input [(ngModel)]="contact.email" [ngModelOptions]="{standalone: true}" type="email" class="form-control" id="inputEmail" placeholder="Email">
      </div>
      <div class="form-group">
        <label for="inputPhone">Phone</label>
        <input (change)="contact.phone = $event.target.value" type="tel" class="form-control" id="inputPhone" placeholder="Phone Number">
      </div>
      <div class="form-group">
        <label for="inputCompany">Company</label>
        <input [(ngModel)]="contact.company" [ngModelOptions]="{standalone: true}" type="text" class="form-control" id="inputCompany" placeholder="Company Name">
      </div>
      <button (click)="submit()" type="submit" class="btn btn-danger" id="submitButton">Submit</button>
    </div>
  </div>
  <h2 align="center">{{message}}</h2>
</div>
