<div id="accordion">
  <div class="card">
    <div class="bg-dark" id="headingOne">
      <h5 class="mb-0">
        <button id="clientButton" (click)="changeTitle()" class="btn btn-link w-100 text-left" data-toggle="collapse" data-target="#frontEnd" aria-expanded="false" aria-controls="collapseOne">
          {{clientTitle}}
        </button>
      </h5>
    </div>
    <div id="frontEnd" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-2"></div>
          <img class="col-3" src="../../../assets/img/angular.png" height="100%" />
          <div class="col-2"></div>
          <img class="col-3" src="../../../assets/img/bootstrap.png" height="100%" />
          <div class="col-2"></div>
        </div>
        The Front End is composed of an Angular 6 application that includes Bootstrap 4. Bootstrap in the documentation
        includes other javascript libraries such as Jquery and Popper. The Javascript libraries are included through
        dependencies within the package.json file of the app and installed through the Node.js environment.
        The Angular components, guards, and services are written in TypeScript and use some Jquery Javascript. Within
        the website's components HttpClient service from HttpClientModule is used to communicate requests made to the
        ASP.NET controller.
      </div>
    </div>
  </div>
  <div class="card">
    <div class="bg-dark" id="serverSideDiv">
      <h5 class="mb-0">
        <button (click)="checkTitle()" class="btn btn-link collapsed w-100 text-left" data-toggle="collapse" data-target="#serverSide" aria-expanded="false" aria-controls="collapseTwo">
          Server Side
        </button>
      </h5>
    </div>
    <div id="serverSide" class="collapse" aria-labelledby="headingServerSide" data-parent="#accordion">
      <div class="card-body">

        <div class="card">
          <div class="bg-dark" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed w-100 text-left" data-toggle="collapse" data-target="#middleware" aria-expanded="false" aria-controls="collapseTwo">
                Middleware
              </button>
            </h5>
          </div>
          <div id="middleware" class="collapse" aria-labelledby="headingTwo" data-parent="#serverSide">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-4"></div>
                <img class="col-4" src="../../../assets/img/controller.png" height="100%" />
                <div class="col-4"></div>
              </div>
              <p>
                The MVC controller for the ASP.NET application takes your information given on the contacts page in the
                form of a .json object converts it to an NHibernate ORM entity. The entity object then creates a session
                to manipulate records in the SQLite database.
              </p>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="bg-dark" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-link collapsed w-100 text-left" data-toggle="collapse" data-target="#backEnd" aria-expanded="false" aria-controls="collapseThree">
                Back End
              </button>
            </h5>
          </div>
          <div id="backEnd" class="collapse" aria-labelledby="headingThree" data-parent="#serverSide">
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-4"></div>
                <img class="col-4" src="../../../assets/img/sqlite.png" height="100%" />
                <div class="col-4"></div>
              </div>
              <p>
                A SQLite database is assigned to the website and the schema contains a table that is
                provided from the contacts. The table's columns map with the NHibernate ORM described in the
                Middleware section. The C# class contains attributes to connect the entity to the table.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- outside collapse-->
  </div>
</div>
