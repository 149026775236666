import { Component } from '@angular/core';

@Component({
    selector: 'app-mvc',
    templateUrl: './mvc.component.html',
    styleUrls: ['./mvc.component.css']
})
/** mvc component*/
export class MvcComponent {
  public clientTitle: string;
  /** about-website ctor */
  constructor() {
    this.clientTitle = 'Client Side';

  }

  changeTitle() {
    this.clientTitle === 'Client Side' ? this.clientTitle = 'Front End' : this.clientTitle = 'Client Side';
  }

  checkTitle() {
    if ($.trim($('#clientButton').text()) === "Front End") {
      this.clientTitle = 'Client Side';
    }
  }
}
