import { Component, AfterViewInit, OnInit } from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-experience',
    templateUrl: './experience.component.html',
    styleUrls: ['./experience.component.css']
})
/** experience component*/
export class ExperienceComponent implements AfterViewInit, OnInit {
  ngOnInit(): void {
    document.addEventListener("resize", ev => {
      this.resizePdf();
    })
    }
  ngAfterViewInit(): void {
    this.resizePdf();
   }
    /** experience ctor */
    constructor() {

  }

  resizePdf() {
    var width = $("#resumePdf").width();
    $("#resumePdf").prop("height", width * 1.5 + "px");
    $("#transcriptPdf").prop("height", width * .9 + "px");
  }
}
